<!--
 * @Description: 按部门添加
 * @Author: xiawenlong
 * @Date: 2021-04-22 10:06:14
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-12-27 10:28:22
-->
<template>
  <el-dialog
    top="5vh"
    :close-on-click-modal="false"
    class="add-student-by-dept"
    :visible.sync="dialogVisible"
  >
    <div class="title">按部门选择员工</div>
    <div class="close-icon" @click="dialogVisible = false">
      <i class="el-icon-close"></i>
    </div>
    <div class="body">
      <div class="content">
        <div class="left">
          <p>已选总人数：{{ selectStudent.length }}人</p>
          <el-scrollbar>
            <el-tree
              ref="tree"
              :data="tree"
              node-key="id"
              check-strictly
              highlight-current
              default-expand-all
              :props="defaultProps"
              @node-click="selectDept"
            >
            </el-tree>
          </el-scrollbar>
        </div>
        <table-list
          ref="studentByDeptTable"
          :loading="loading"
          :data="studentList"
          :search-form="searchForm(this)"
          :columns="columns(this)"
          :pager="pager"
          :options="{
            selection: true,
            reserveSelection: true,
            rowKey: 'studentId',
            selectable: selectEvent,
          }"
          @search="onSearch"
          @selectionChange="handleSelectionChange"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></table-list>
      </div>
    </div>
    <div class="footer">
      <el-button @click="dialogVisible = false">取消</el-button>
      <el-button :loading="submitLoading" type="primary" @click="submit">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
const searchForm = () => [
  {
    label: '请输入姓名进行搜索',
    prop: 'keyword',
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'studentName',
    label: '员工姓名',
    minWidth: 120,
  },
  {
    prop: 'mobile',
    label: '手机号',
    minWidth: 120,
  },
  {
    prop: 'cardNo',
    label: '身份证号',
    minWidth: 200,
  },
]
import TableList from '@/components/TableList'
import { studentByDept, studentsAdd, studentsDept } from '@/api/class'
import to from 'await-to'
export default {
  name: 'AddStudentByClass',
  components: { TableList },
  data() {
    return {
      searchForm,
      columns,
      dialogVisible: false,
      loading: false,
      studentList: [],
      tree: [],
      defaultProps: {
        id: 'deptId',
        label: function(data) {
          return data.name + '(' + data.studentCount + ')' + '人'
        },
      },
      selectStudent: [],
      deptId: '',
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      submitLoading: false,
      queryParams: {},
    }
  },
  methods: {
    show() {
      this.dialogVisible = true
      this.pager.current = 1
      this.studentList = []
      this.getTreeList()
      this.$nextTick(() => {
        this.$refs.studentByDeptTable.$refs.tableList.clearSelection()
      })
    },
    async getStudentList() {
      if (this.loading) return
      const { current, size } = this.pager
      const { classId } = this.$route.params
      const { deptId } = this
      this.loading = true
      const [res, err] = await to(
        studentByDept({ classId, deptId, current, size, ...this.queryParams }),
      )
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.studentList = res.data.list
      this.pager.total = res.data.total
    },
    //树形图
    async getTreeList() {
      const [res, err] = await to(studentsDept({}))
      if (err) return this.$message.warning(err.msg)
      this.tree = res.data
      this.deptId = res.data[0].deptId
      this.getStudentList()
    },
    selectDept(item) {
      this.deptId = item.deptId
      this.pager.current = 1
      this.getStudentList()
    },
    async submit() {
      if (this.submitLoading) return
      if (!this.selectStudent.length) return this.$message.warning('请先选择员工')
      const { classId } = this.$route.params
      this.submitLoading = true
      const [, err] = await to(
        studentsAdd({ classId, students: this.selectStudent.map(item => item.studentId) }),
      )
      this.submitLoading = false
      if (err) return this.$message.warning(err.msg)
      this.$message.success('添加成功')
      this.dialogVisible = false
      this.$emit('success')
    },
    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      this.queryParams = queryParams
      this.getStudentList()
    },
    // 多选
    handleSelectionChange(val) {
      this.selectStudent = val
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getStudentList()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getStudentList()
    },
    selectEvent(row) {
      return !row.flag
    },
  },
}
</script>
<style lang="scss" scoped>
.add-student-by-dept {
  ::v-deep.el-dialog {
    position: relative;
    width: 750px;
    background: #ffffff;
    border-radius: 0;
    color: #4d4e5d;
    .close-icon {
      z-index: 99;
      cursor: pointer;
      position: absolute;
      right: 24px;
      top: 14px;
      font-size: 25px;
      font-weight: bold;
      color: #8c8c8c;
    }
    .el-dialog__header {
      display: none;
    }
    .el-tabs__header {
      margin-bottom: 26px;
      padding-top: 23px;
    }
    .el-dialog__body {
      padding: 0;
    }
    .body {
      box-sizing: border-box;
      padding: 0 50px 20px 20px;
    }
    .title {
      height: 60px;
      line-height: 60px;
      border-bottom: 1px solid #c9c9c9;
      padding-left: 30px;
      font-size: 18px;
    }
    .content {
      display: flex;
      .left {
        width: 230px;
        padding-top: 20px;
        p {
          font-size: 14px;
          margin-bottom: 20px;
          line-height: 40px;
          padding-left: 10px;
          font-weight: 500;
        }
        .el-scrollbar {
          height: 520px;
        }
        .el-scrollbar__wrap {
          overflow: scroll;
          overflow-x: hidden;
          width: 110%;
          height: 100%;
        }
        .classList {
          height: 520px;
          padding-right: 10px;
          padding-top: 20px;
          li {
            box-sizing: border-box;
            cursor: pointer;
            line-height: 50px;
            width: 150px;
            padding: 0 20px;
            margin-bottom: 5px;
            border-radius: 4px;
            @extend %one-line-text;
            &:hover {
              background-color: #fff2eb;
            }
            &.active {
              background-color: #ff7b33;
              color: #fff;
            }
          }
        }
      }
      .table-list {
        flex: 1;
        // margin-left: 10px;
        .el-checkbox__input.is-disabled .el-checkbox__inner {
          display: none;
        }
        .el-table th > .cell {
          padding-left: 14px;
        }
        .el-table__header {
          th {
            background: #eaeaea;
          }
        }
        .el-table__body {
          td {
            background: #fff;
            border: 0;
          }
        }
        .form {
          padding: 0;
          display: flex;
          .el-input,
          input {
            width: 194px !important;
          }
          .el-button--primary {
            width: 98px;
            margin-left: 20px;
          }
          .el-button--default {
            display: none;
          }
        }
      }
    }
    .footer {
      padding: 20px 52px 20px;
      text-align: right;
      border-top: 1px solid #c7c7c7;
    }
  }
}
</style>
