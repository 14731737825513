<!--
 * @Description: 选择班主任
 * @Author: xiawenlong
 * @Date: 2021-04-22 10:06:14
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-12-24 17:26:01
-->
<template>
  <el-dialog
    top="5vh"
    :close-on-click-modal="false"
    class="add-student"
    :visible.sync="dialogVisible"
  >
    <div class="title">按员工列表选择</div>
    <div class="close-icon" @click="dialogVisible = false">
      <i class="el-icon-close"></i>
    </div>
    <div class="body">
      <div class="content">
        <p>已选总人数：{{ selectStudent.length }}人</p>
        <table-list
          ref="studentsByOrgTable"
          :loading="loading"
          :data="studentsByOrg"
          :search-form="searchForm(this)"
          :columns="columns(this)"
          :pager="pager"
          :options="{
            selection: true,
            reserveSelection: true,
            rowKey: 'studentId',
            selectable: selectEvent,
            pageSize: [10, 20, 50, 100, 500],
            maxHeight: 600,
          }"
          @search="onSearch"
          @selectionChange="handleSelectionChange"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></table-list>
      </div>
      <div class="footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button :loading="submitLoading" type="primary" @click="submit">确定</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
const searchForm = () => [
  {
    label: '姓名进行搜索',
    prop: 'keyword',
    width: 150,
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'studentName',
    label: '姓名',
    minWidth: 120,
  },
  {
    prop: 'mobile',
    label: '手机号',
    minWidth: 150,
  },
  {
    prop: 'cardNo',
    label: '证件号',
    minWidth: 250,
  },
  {
    prop: 'deptList',
    label: '部门',
    minWidth: 150,
    render: (h, { row }) => {
      let deptList = []
      row.deptList.forEach(dept => {
        deptList.push(h('div', dept.deptName))
      })
      return h('div', deptList)
    },
  },
]
import TableList from '@/components/TableList'
import { studentsByOrg, studentsAdd } from '@/api/class'
import to from 'await-to'
export default {
  name: 'AddStudent',
  components: { TableList },
  data() {
    return {
      searchForm,
      columns,
      dialogVisible: false,
      loading: false,
      studentsByOrg: [],
      selectStudent: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      submitLoading: false,
      queryParams: {},
    }
  },
  methods: {
    show() {
      this.dialogVisible = true
      this.pager.current = 1
      this.getStudentList()
      this.$nextTick(() => {
        this.$refs.studentsByOrgTable.$refs.tableList.clearSelection()
      })
    },
    async getStudentList() {
      if (this.loading) return
      const { current, size } = this.pager
      const { classId } = this.$route.params
      this.loading = true
      const [res, err] = await to(studentsByOrg({ classId, current, size, ...this.queryParams }))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.studentsByOrg = res.data.list
      this.pager.total = res.data.total
    },
    async submit() {
      if (this.submitLoading) return
      if (!this.selectStudent.length) return this.$message.warning('请先选择员工')
      const { classId } = this.$route.params
      this.submitLoading = true
      const [, err] = await to(
        studentsAdd({ classId, students: this.selectStudent.map(item => item.studentId) }),
      )
      this.submitLoading = false
      if (err) return this.$message.warning(err.msg)
      this.$message.success('添加成功')
      this.dialogVisible = false
      this.$emit('success')
    },
    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      this.queryParams = queryParams
      this.getStudentList()
    },
    // 多选
    handleSelectionChange(val) {
      this.selectStudent = val
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getStudentList()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getStudentList()
    },
    selectEvent(row) {
      return !row.flag
    },
  },
}
</script>
<style lang="scss" scoped>
.add-student {
  ::v-deep.el-dialog {
    position: relative;
    width: 960px;
    background: #ffffff;
    border-radius: 0;
    color: #4d4e5d;
    .close-icon {
      z-index: 99;
      cursor: pointer;
      position: absolute;
      right: 24px;
      top: 10px;
      font-size: 25px;
      font-weight: bold;
      color: #8c8c8c;
    }
    .el-dialog__header {
      display: none;
    }
    .el-tabs__header {
      margin-bottom: 26px;
      padding-top: 23px;
    }
    .el-dialog__body {
      padding: 0;
    }
    .body {
      box-sizing: border-box;
      padding: 0 20px 20px 20px;
    }
    .title {
      height: 55px;
      line-height: 55px;
      border-bottom: 1px solid #c9c9c9;
      padding-left: 30px;
      font-size: 18px;
    }
    .content {
      .table-list {
        .el-checkbox__input.is-disabled .el-checkbox__inner {
          display: none;
        }
        .el-table th > .cell {
          padding-left: 14px;
        }
      }
      p {
        position: absolute;
        top: 90px;
        right: 40px;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 10px;
        line-height: 40px;
      }
    }
    .footer {
      padding-top: 20px;
      text-align: right;
    }
  }
}
</style>
