<!--
 * @Description: 添加员工
 * @Author: xiawenlong
 * @Date: 2021-04-27 11:41:42
 * @LastEditors: xiawenlong
 * @LastEditTime: 2022-03-01 09:37:10
-->
<template>
  <div class="class-add-student">
    <!-- <el-button type="primary" @click="$router.push('/class/list')">返回班级列表</el-button> -->
    <table-list
      title="添加班级员工"
      :loading="loading"
      :data="classList"
      :btns="btns(this)"
      :columns="columns(this)"
      :pager="pager"
      :back-btn="{
        name: '返回班级列表',
        method: () => {
          this.$router.push('/class/list')
        },
      }"
      :options="{ selection: true, operatesWidth: 200, selectable: selectEvent }"
      @search="onSearch"
      @selectionChange="handleSelectionChange"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
      <div slot="subTitle" class="subTitle">已添加员工</div>
    </table-list>
    <add-student ref="addStudent" @success="getClasses"></add-student>
    <add-student-by-class ref="addStudentByClass" @success="getClasses"></add-student-by-class>
    <add-student-by-dept ref="addStudentByDept" @success="getClasses"></add-student-by-dept>
    <import-add ref="importAdd" @success="getClasses"></import-add>
  </div>
</template>

<script>
const btns = _this => [
  {
    label: '按现有班级添加',
    method: _this.handleAddByClass,
  },
  {
    label: '按部门添加',
    method: _this.handleAddByDept,
  },
  {
    label: '按员工列表添加',
    method: _this.handleAdd,
  },
  {
    label: '批量导入',
    method: _this.handleAddImport,
  },
  {
    label: '删除',
    method: _this.handleDelete,
    type: 'danger',
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'studentName',
    label: '员工名称',
    minWidth: 150,
  },
  {
    prop: 'mobile',
    label: '手机号码',
    minWidth: 150,
  },
  {
    prop: 'cardNo',
    label: '身份证/护照',
    minWidth: 250,
  },
  {
    prop: 'deptList',
    label: '部门',
    minWidth: 150,
    render: (h, { row }) => {
      let deptList = []
      row.deptList.forEach(dept => {
        deptList.push(h('div', dept.deptName))
      })
      return h('div', deptList)
    },
  },
]
import TableList from '@/components/TableList'
import { classStudents, studentsRemove } from '@/api/class'
import to from 'await-to'
import AddStudentByClass from './components/AddStudentByClass'
import AddStudent from './components/AddStudent'
import AddStudentByDept from './components/AddStudentByDept'
import ImportAdd from './components/ImportAdd'
export default {
  name: 'ClassAddStudent',
  components: {
    TableList,
    AddStudentByClass,
    AddStudent,
    AddStudentByDept,
    ImportAdd,
  },
  data() {
    return {
      btns,
      columns,
      loading: false,
      classList: [],
      selectStudents: [],
      deptList: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      queryParams: {},
    }
  },
  mounted() {
    this.getClasses()
  },
  methods: {
    async getClasses() {
      if (this.loading) return
      const { current, size } = this.pager
      const { classId } = this.$route.params
      this.loading = true
      const [res, err] = await to(classStudents({ classId, current, size, ...this.queryParams }))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.classList = res.data.list
      this.pager.total = res.data.total
    },
    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      this.queryParams = queryParams
      this.getClasses()
    },
    handleAddByClass() {
      this.$refs.addStudentByClass.show()
    },
    handleAddImport() {
      this.$refs.importAdd.show()
    },
    handleAddByDept() {
      this.$refs.addStudentByDept.show()
    },
    handleAdd() {
      this.$refs.addStudent.show()
    },
    // 删除
    async handleDelete() {
      if (!this.selectStudents.length) return this.$message.warning('请先选择要删除的员工')
      let students = []
      this.selectStudents.map(item => students.push(item.studentId))
      this.$confirm('确认删除员工吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const { classId } = this.$route.params
        const [, err] = await to(studentsRemove({ classId, students }))
        if (err) return this.$message.warning(err.msg)
        this.$message.success('删除成功')
        this.getClasses()
      })
    },
    // 多选
    handleSelectionChange(val) {
      this.selectStudents = val
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getClasses()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getClasses()
    },
    selectEvent(row) {
      return !row.isSettle
    },
  },
}
</script>
<style lang="scss" scoped>
.class-add-student {
  .subTitle {
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: #4d4e5d;
    margin-bottom: 12px;
  }
  ::v-deep .table-list {
    .el-checkbox__input.is-disabled .el-checkbox__inner {
      display: none;
    }
  }
}
</style>
