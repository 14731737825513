<template>
  <el-dialog
    top="5vh"
    :close-on-click-modal="false"
    class="import-add"
    :visible.sync="dialogVisible"
  >
    <div class="title">批量导入员工</div>
    <div class="close-icon" @click="dialogVisible = false">
      <i class="el-icon-close"></i>
    </div>
    <div class="body">
      <el-upload
        class="upload-batch"
        drag
        action=""
        :auto-upload="false"
        :file-list="fileList"
        :on-change="handleChange"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div slot="tip" class="el-upload__tip">
          <div class="btns">
            <!-- <el-button :loading="loading" type="primary" @click="importSubmit">确定导入</el-button> -->
            <el-button @click="downloadTemplate">下载导入模板</el-button>
          </div>
          <p>模版说明：</p>
          <p>员工姓名：员工的真实姓名。</p>
          <p>身份证：员工对应的18位身份证号。</p>
        </div>
      </el-upload>
    </div>
    <div class="footer">
      <el-button @click="dialogVisible = false">取消</el-button>
      <el-button :loading="loading" type="primary" @click="importSubmit">确定导入</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { classStudentImportTemplate, classStudentImport } from '@/api/class'
import to from 'await-to'
export default {
  name: 'ImportAdd',
  data() {
    return {
      dialogVisible: false,
      loading: false,
      fileList: [],
      classId: this.$route.params.classId,
    }
  },
  methods: {
    show() {
      this.fileList = []
      this.dialogVisible = true
    },
    async downloadTemplate() {
      const [res, err] = await to(classStudentImportTemplate())
      if (err) return this.$message.warning(err.msg)
      const blob = new Blob([res], { type: 'application/vnd.ms-excel' })
      if (window.navigator.msSaveOrOpenBlob) {
        //兼容IE10
        navigator.msSaveBlob(blob, this.filename)
      } else {
        const href = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = href
        a.download = '批量导入班级'
        a.click()
        URL.revokeObjectURL(a.href)
      }
    },
    handleChange(file, fileList) {
      const isLt2M = file.size / 1024 / 1024 < 5
      if (!isLt2M) {
        this.fileList = []
        return this.$message.warning('上传文件大小不能超过 5MB!')
      }
      if (fileList.length > 0) {
        this.fileList = [fileList[fileList.length - 1]]
      }
      var extName = file.name.substring(file.name.lastIndexOf('.')).toLowerCase()
      var AllUpExt = '.xls|.xlsx|'
      if (AllUpExt.indexOf(extName + '|') === -1) {
        this.fileList = []
        this.$message.warning('仅支持上传excel文件，请重新上传!')
      }
    },
    async importSubmit() {
      if (this.loading) return
      if (!this.fileList.length) return this.$message.warning('请先选择文件')
      let formData = new FormData()
      if (this.classId == undefined) {
        this.classId = ''
      }
      formData.append('file', this.fileList[0].raw)
      formData.append('classId', this.classId)
      this.loading = true
      const [, err] = await to(classStudentImport(formData))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.$message.success('导入成功')
      this.dialogVisible = false
      this.$emit('success')
    },
  },
}
</script>
<style lang="scss" scoped>
.import-add {
  ::v-deep.el-dialog {
    position: relative;
    width: 650px;
    background: #ffffff;
    border-radius: 0;
    color: #4d4e5d;
    .close-icon {
      z-index: 99;
      cursor: pointer;
      position: absolute;
      right: 24px;
      top: 14px;
      font-size: 25px;
      font-weight: bold;
      color: #8c8c8c;
    }
    .el-dialog__header {
      display: none;
    }
    .el-tabs__header {
      margin-bottom: 26px;
      padding-top: 23px;
    }
    .el-dialog__body {
      padding: 0;
    }
    .body {
      box-sizing: border-box;
      padding: 50px 50px 50px 20px;
    }
    .title {
      height: 60px;
      line-height: 60px;
      border-bottom: 1px solid #c9c9c9;
      padding-left: 30px;
      font-size: 18px;
    }
    .footer {
      padding: 20px 52px 20px;
      text-align: right;
      border-top: 1px solid #c7c7c7;
    }
  }
  ::v-deep.upload-batch {
    width: 376px;
    margin: 0 auto;
    position: relative;
    .el-upload-list {
      position: absolute;
      width: 100%;
      left: 0;
      top: 180px;
    }
    .el-upload {
      &__tip {
        font-size: 14px;
        color: #333333;
        line-height: 22px;
        .btns {
          //   padding: 0 60px;
          display: flex;
          justify-content: space-between;
          margin-bottom: 35px;
        }
      }
      .el-upload-dragger {
        width: 400px;
        height: 180px;
        background: #f5f5f5;
        border-radius: 10px;
        margin-bottom: 50px;
        // padding-top: 60px;
        .el-icon-upload {
          //   font-size: 105px;
          margin-bottom: 26px;
        }
      }
    }
  }
}
</style>
