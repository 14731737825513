var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{staticClass:"add-student",attrs:{"top":"5vh","close-on-click-modal":false,"visible":_vm.dialogVisible},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',{staticClass:"title"},[_vm._v("按员工列表选择")]),_c('div',{staticClass:"close-icon",on:{"click":function($event){_vm.dialogVisible = false}}},[_c('i',{staticClass:"el-icon-close"})]),_c('div',{staticClass:"body"},[_c('div',{staticClass:"content"},[_c('p',[_vm._v("已选总人数："+_vm._s(_vm.selectStudent.length)+"人")]),_c('table-list',{ref:"studentsByOrgTable",attrs:{"loading":_vm.loading,"data":_vm.studentsByOrg,"search-form":_vm.searchForm(this),"columns":_vm.columns(this),"pager":_vm.pager,"options":{
          selection: true,
          reserveSelection: true,
          rowKey: 'studentId',
          selectable: _vm.selectEvent,
          pageSize: [10, 20, 50, 100, 500],
          maxHeight: 600,
        }},on:{"search":_vm.onSearch,"selectionChange":_vm.handleSelectionChange,"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1),_c('div',{staticClass:"footer"},[_c('el-button',{on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("取消")]),_c('el-button',{attrs:{"loading":_vm.submitLoading,"type":"primary"},on:{"click":_vm.submit}},[_vm._v("确定")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }