var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"class-add-student"},[_c('table-list',{attrs:{"title":"添加班级员工","loading":_vm.loading,"data":_vm.classList,"btns":_vm.btns(this),"columns":_vm.columns(this),"pager":_vm.pager,"back-btn":{
      name: '返回班级列表',
      method: function () {
        this$1.$router.push('/class/list')
      },
    },"options":{ selection: true, operatesWidth: 200, selectable: _vm.selectEvent }},on:{"search":_vm.onSearch,"selectionChange":_vm.handleSelectionChange,"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}},[_c('div',{staticClass:"subTitle",attrs:{"slot":"subTitle"},slot:"subTitle"},[_vm._v("已添加员工")])]),_c('add-student',{ref:"addStudent",on:{"success":_vm.getClasses}}),_c('add-student-by-class',{ref:"addStudentByClass",on:{"success":_vm.getClasses}}),_c('add-student-by-dept',{ref:"addStudentByDept",on:{"success":_vm.getClasses}}),_c('import-add',{ref:"importAdd",on:{"success":_vm.getClasses}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }